import React from "react"
import { Link, graphql } from "gatsby"
import { Container, Row, Col } from "react-grid-system"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgAvatar from "../images/dnowak-avatar.png"
import imgFB from "../images/social/001-facebook.svg"
import imgTwitter from "../images/social/002-twitter.svg"
import imgLinkedIn from "../images/social/003-linkedin.svg"

import * as styles from "./blogpost.module.scss"

const AuthorInfo = () => (
  <div className={styles.author}>
    <div style={{ float: "left" }}>
      <img src={imgAvatar} width={50} alt="Author avatar" />
    </div>
    <div className={styles.authorBio}>
      <h6>Dagmara Nowak</h6>
      Właścicielka biura rachunkowego, wieloletnia praktyczka prawa podatkowego
      i bilansowego, z czego przyznaje od razu, woli prawo podatkowe :)
      Zwolenniczka nowych technologii. Nie lubi papierów, stąd jej dążenie do
      stworzenia biura rachunkowego z jak najmniejszą ilością papierowych faktur
      i wydruków. Zmiany podatkowe uważa za nowe, ekscytujące wyzwania, a nie
      coś strasznego, do czego znowu trzeba się dostosować.
    </div>
    <div style={{ clear: "both" }}></div>
  </div>
)

export default function Template({ data, location }) {
  const { markdownRemark } = data
  const { frontmatter, fields, html } = markdownRemark
  const featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid

  return (
    <Layout location={fields.slug} style={{ backgroundColor: "#f6f6f8" }}>
      <SEO title={frontmatter.title} />
      <Container>
        <Row style={{ marginTop: "2em" }}>
          <Col xs={12}>
            <div className={styles.content}>
              <div className={styles.miniHeader}>
                <Link to="/blog">Blog</Link>
              </div>
              <Img fluid={featuredImgFluid} />
              <div className={styles.metadata}>
                {frontmatter.tags.map(t => (
                  <span key={`tag-${t}`}>{t}</span>
                ))}
                <div className={styles.date}>{frontmatter.date}</div>
              </div>
              <h1>{frontmatter.title}</h1>
              <AuthorInfo />
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
            <div className={styles.shamelessPlug}>
              <div className={styles.cta}>
                Zmniejsz koszty Twojego biura rachunkowego
                <div className={styles.btn}>
                  <Link to="/#subscribe">Zacznij korzystać z Engrafy</Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className={`${styles.content} ${styles.footer}`}>
          <Row>
            <Col md={6}>
              <div className={styles.footerHeader}>Napisane przez</div>
              <AuthorInfo />
              <div className={styles.footerHeader}>Podziel się tym postem</div>
              <div className={styles.social}>
                <a href={`https://facebook.com/sharer.php?u=${location.href}`}>
                  <div className={styles.icon}>
                    <img src={imgFB} alt="Facebook" />
                  </div>
                </a>
                <a
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=${location.href}&title=${frontmatter.title}`}
                >
                  <div className={styles.icon}>
                    <img src={imgLinkedIn} alt="Linkedin" />
                  </div>
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?text=${frontmatter.title}%20${location.href}`}
                >
                  <div className={styles.icon}>
                    <img src={imgTwitter} alt="Twitter" />
                  </div>
                </a>
              </div>
            </Col>
            <Col md={6}>
              <p>
                Engrafa ułatwi Ci pracę w biurze rachunkowym. Pomoże Ci
                automatycznie czytać skany i faktury elektroniczne oraz doda je
                do Twojego programu księgowego. Da Ci też intuicyjne narzędzia
                pomagające w codziennej pracy w Twojej firmie.
              </p>
              <p>
                Zmniejszasz koszty, poprawiasz wydajność oraz oszczędzasz czas
                dzięki wdrożeniu prostej i łatwej aplikacji.
              </p>
              <p>
                Możesz zainstalować ją samodzielnie, nie potrzebujesz do tego
                wdrożeniowców i kilku godzin Twojej pracy <span role="img" aria-label="smile">🙂</span>
              </p>
              <p>
                <b>Sprawdź teraz za darmo, bez żadnych zobowiązań!</b>
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        tags
        title
        date
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
